import * as React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../layouts/default'
import logoBigGif from '../images/logo-big.gif'
import EngagementBlock from '../components/engagement/engagement-block'
import CarLoanImg from '../icons/car-loan.png'
import PersonalLoanImg from '../icons/deposit.png'
import BusinessLoanImg from '../icons/sign-contract.png'
import HomeLoanImg from '../icons/home-loan.png'
import ExpatLoanImg from '../icons/percentage-rate.png'
import RefinanceImg from '../icons/dynamic-rate.png'
import NonResidentLoanImg from '../icons/consumer-credit.png'
import LowDocLoanImg from '../icons/interest-rate.png'
import LineOfCreditImg from '../icons/credit-card.png'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'

const Hero = styled.div`
  /* background: #f2ebe2 */
  background: rgb(245,239,231);
  background: linear-gradient(5deg, rgba(245,239,231,1) 0%, rgba(245,239,231,1) 45%, rgba(252,250,248,1) 84%);
  height: 750px;
`

const Description = styled.p`
  color: #1F3F68;
  opacity: 0.6;
  width: ${({ width }) => width ?? '450px'};
`

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 64px;
  line-height: 74px;
  color: #1F3F68;
`

const ContactUsHeroButton = styled.a`
  background: #BE996A;
  box-shadow: 5px 10px 20px rgba(53, 110, 173, 0.2);
  border-radius: 10px;
  height: 56px;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: 40px;
`

const ServiceRow = styled.div`
  width: 1000px;
`

const ServiceBlock = styled.a`
  width: 300px;
  height: 200px;
  text-align: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 24px;
  color: #1F3F68;
  position: relative;
  padding-top: 130px;

  img {
    position: absolute;
    top: 0;
    transition: width 0.1s, height 0.1s;
  }

  :hover {
    img {
      width: 115px;
      height: 115px;
    }
  }
`

const Home = ({path}) => {
  return (
    <DefaultLayout path={path}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CY Finance</title>
      </Helmet>
      <Header index={1}/>
      <Hero className="flex justify-center gap-40">
        <div className="flex flex-col justify-center">
          <div>
            <Title>
              Simplifying Your <br/> Financial Need 
            </Title>
            <Title>
              Helping You Better
            </Title>
          </div>

          <Description className="mt-4">
            CY Finance is an accredited mortgage broking company in Australia with specialising in both residential and commercial lending.
          </Description>

          <ContactUsHeroButton href="/about">
            Contact Us
          </ContactUsHeroButton>
        </div>
        <div style={{ width: '500px', height: 'auto', backgroundImage: `url(${logoBigGif})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
      </Hero>
      <div className='flex flex-col justify-center p-6 py-24'>
        <Title className='text-center'>Our Services</Title>
        <Description className='text-center m-auto mt-4' width='600px'>
          We deal with a wide variety of banks and non-banking lenders. By continually keeping up-to-date with the latest lending policies and products, our mission is to help you obtain the product that bet suites your circumstance and needs.
        </Description>
        <ServiceRow className='flex justify-between mx-auto mt-20'>
          <ServiceBlock href='/services'>
            <img src={HomeLoanImg} alt="home-loan" className="h-28 w-28" />
            Home Loan - New Purchase
          </ServiceBlock>
          <ServiceBlock href='/services'>
            <img src={RefinanceImg} alt="refinance" className="h-28 w-28" />
            Home Loan - Refinance
          </ServiceBlock>
          <ServiceBlock href='/services'>
            <img src={CarLoanImg} alt="car-loan" className="h-28 w-28" />
            Car Loan
          </ServiceBlock>
        </ServiceRow>
        <ServiceRow className='flex justify-between mx-auto mt-20'>
          <ServiceBlock href='/services'>
            <img src={PersonalLoanImg} alt="personal-loan" className="h-28 w-28" />
            Personal Loan
          </ServiceBlock>
          <ServiceBlock href='/services'>
            <img src={BusinessLoanImg} alt="business-loan" className="h-28 w-28" />
            Business Loan & Asset Finance
          </ServiceBlock>
          <ServiceBlock href='/services'>
            <img src={ExpatLoanImg} alt="expat-loan" className="h-28 w-28" />
            Expat Loan
          </ServiceBlock>
        </ServiceRow>
        <ServiceRow className='flex justify-between mx-auto mt-20'>
          <ServiceBlock href='/services'>
            <img src={NonResidentLoanImg} alt="non-resident-loan" className="h-28 w-28" />
            Non-Resident Loan
          </ServiceBlock>
          <ServiceBlock href='/services'>
            <img src={LowDocLoanImg} alt="low-doc-loan" className="h-28 w-28" />
            Low Doc Loan
          </ServiceBlock>
          <ServiceBlock href='/services'>
            <img src={LineOfCreditImg} alt="line-of-credit" className="h-28 w-28" />
            Line of Credit
          </ServiceBlock>
        </ServiceRow>
      </div>
      <EngagementBlock />
      <Footer index={1} />
    </DefaultLayout>
  )
}

export default Home
